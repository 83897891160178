import {DetectAdblock} from '@scthakuri/adblock-detector';

/**
 * HELPER COOKIE FUNCTIONS
 * https://javascript.info/cookie
 */

// SET
function uanmSetCookie(name, value, options = {}) {

  options = {
    path: '/',
    // add other defaults here if necessary
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[ optionKey ];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

// GET
function uanmGetCookie(name) {
  // Split cookie string and get all individual name=value pairs in an array
  let cookieArr = document.cookie.split(';');

  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[ i ].split('=');

    /* Removing whitespace at the beginning of the cookie name
     and compare it with the given string */
    if (name == cookiePair[ 0 ].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[ 1 ]);
    }
  }

  // Return null if not found
  return null;
};

// DELETE
function uanmDeleteCookie(name) {
  setCookie(name, '', {
    'max-age': -1,
  });
}

/**
 * DOM LOADED
 */
document.addEventListener('DOMContentLoaded', (event) => {

  // VARS
  const uanmCookieName = 'knowit_uanm_shown';
  const targetMsgDiv = document.getElementById('knowit-uanm');
  const closeBtn = document.getElementById('knowit-uanm--close-button');
  //const targetMsgDivInner = document.getElementById('knowit-uanm-message--inner');
  let targetMsgDivHeight = targetMsgDiv.offsetHeight;
  // Set initial height with js, so we can animate
  targetMsgDiv.setAttribute('style', 'height:' + targetMsgDivHeight + 'px');

  // RESIZE
  window.addEventListener('resize', (event) => {
    // Add dynamic styles
    targetMsgDiv.setAttribute('style', 'height:auto');
    // Set the dynamic height
    let targetMsgDivHeight = targetMsgDiv.offsetHeight;
    targetMsgDiv.setAttribute('style', 'height:' + targetMsgDivHeight + 'px');
  });

  // AD BLOCK
  DetectAdblock((detected) => {
    if (detected) {
      // Adblock is detected
      // alert('detected');

      console.log('%c Knowit cookie', 'color:cyan');
      console.log(uanmGetCookie(uanmCookieName));

      // Check if the cookie is set / Banner has been seen or not
      if (uanmGetCookie(uanmCookieName)) {
        // Cookie found -> hide message
        targetMsgDiv.classList.add('hide-after-cookie');
      }
    }
    else {
      // Adblock is not detected
      // alert('nada');

      // Get rid of the element
      targetMsgDiv.remove();

    }
  });

  // Button
  closeBtn.addEventListener('click', (e) => {
    // targetMsgDiv.style.display = 'none';
    targetMsgDiv.style.height = '0px';

    // Otherwise Set the cookie
    uanmSetCookie(uanmCookieName, true, {secure: true, samesite: 'lax'});
  });

});
